import { useState, useEffect } from 'react';
import { useAppLoaded } from '../contexts/AppLoadedProvider';

import { useSessionSocket } from '../contexts/EveryMatrixProvider';

const useSocketCall = (callMethod, parameters = null) => {

    const sessionSocket = useSessionSocket()
    const { appLoaded } = useAppLoaded()

    const [response, setResponse] = useState(undefined)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(true)
    const [resultReturned , setResultReturned ] = useState(false)


    useEffect(() => {

        if(!appLoaded) return
        if(!sessionSocket){ return }
        if(resultReturned){ return }

        sessionSocket.call(callMethod, [], parameters).then(
            function(result){
                setResponse(result)
                setLoading(false)
                setResultReturned(true);
                return result
            },
            function(err){
                setError(err)
                setLoading(false)
                setResultReturned(true);
                return err
            }
        )
    }, [appLoaded, resultReturned, callMethod, sessionSocket, parameters]);

    return { response, error, loading }

}

export default useSocketCall