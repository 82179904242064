import { React, useState, useEffect, lazy, Suspense } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Helmet } from 'react-helmet-async'

import { useUserLogged } from '../../../contexts/UserLoggedProvider'
import { useUserSession } from '../../../contexts/UserSessionProvider'
import { useAppLoaded } from '../../../contexts/AppLoadedProvider'
import { useLoginPopup, useQuickRegisterPopup } from '../../../contexts/LoginPopupProvider'

import { headerMainMenu } from './HeaderData'

import './Header.scss'

const renderLoader = () => <></>

const Button = lazy(() => import('../../Utils/Button/Button'))

const AmountComponent = lazy(() => import('./ServiceMenu/AmountComponent'))
const DepositComponent = lazy(() => import('./ServiceMenu/DepositComponent'))
const UserComponent = lazy(() => import('./ServiceMenu/UserComponent'))

const HeaderMenu = lazy(() => import('../../Navigation/HeaderMenu/HeaderMenu'))
const BurgerMenuButton = lazy(() => import('./BurgerMenu/BurgerMenuButton'))
const BurgerMenu = lazy(() => import('./BurgerMenu/BurgerMenu'))

const Logo = lazy(() => import('./Logo'))

const QuickRegister = lazy(() => import('../../Authentication/QuickRegister/QuickRegister'))
const LoginForm = lazy(() => import('../../Authentication/LoginForm/LoginForm'))

//import LanguageComponent from './ServiceMenu/LanguageComponent'

export default function Header(){

    const { appLoaded } = useAppLoaded()
    const { userSession } = useUserSession()
    const { userLogged } = useUserLogged()
    
    const history = useLocation()
    
    const [ deposit, setDeposit] = useState('home')
    const [ themeColor, setThemeColor] = useState('#161f2c')
    const [ burgerMenuOpened, setBurgerMenuOpened] = useState(false)

    useEffect(() => {

        if(history.pathname === '/live-casino') {
            setDeposit('live-casino')
            setThemeColor('#F69534')
        } else if (history.pathname === '/casino') {
            setDeposit('casino')
            setThemeColor('#E25454')
        } else if(history.pathname === '/sports') {
            setDeposit('sports')
            setThemeColor('#23A04A')
        } else if (history.pathname === '/') {
            setDeposit('home')
            setThemeColor('#161f2c')
        } else {
            setDeposit('home')
            setThemeColor('#161f2c')
        }
    },[history]);

    return (
        <>

            <Helmet>
                <title>{ process.env.REACT_APP_MAIN_TITLE }</title>
                <meta name="description" content={process.env.REACT_APP_MAIN_DESCRIPTION} />
                <link rel="icon" href={"/favicon-live-casino.png"} />
                <meta name="theme-color" content={themeColor} />
                <link rel="apple-touch-icon" href={"/favicon-live-casino.png"} />
                {/* <script>
                    var io_install_flash = false;
                    var io_install_stm = false;
                </script>
                <script type="text/javascript" src="https://mpsnare.iesnare.com/snare.js"></script> */}
            </Helmet>

            <header>
                <div className="inner-header">
                    <Suspense fallback={renderLoader()}>
                        <div className="logo">
                            <Link title="Logo" alt="Logo" to="/">
                                <div className="main-logo">
                                    { <Logo className={deposit} /> }
                                </div>
                                <div className="mobile-logo">
                                    <img src="/images/logos/mobile-logo.svg" alt="Mobile Logo" />
                                </div>
                            </Link>
                        </div>
                        
                        <HeaderMenu items={headerMainMenu} />
                        <BurgerMenuButton open={burgerMenuOpened} setOpen={setBurgerMenuOpened} />
                        <BurgerMenu items={headerMainMenu} open={burgerMenuOpened} setOpen={setBurgerMenuOpened} />
                        
                        <div className='login-wrap'>
                            {/* <LanguageComponent /> */}
                            { userLogged && userSession && <CurrentUser email={userSession.email} /> }
                            { appLoaded && !userLogged && <LoginRegisterButtons deposit={deposit} /> }
                        </div>
                    </Suspense>
                </div>
            </header>
            
            <Suspense fallback={renderLoader()}>
                { appLoaded && 
                    <>
                        <QuickRegister />
                        <LoginForm returnPath={history?.state?.returnPath} />
                    </>
                }
            </Suspense>
        </>

        
    )
    
}


const CurrentUser = () => {

    const { appLoaded } = useAppLoaded()
    
    return (
        <div style={{ opacity: !appLoaded ? 0.1 : 1 }} className="service-menu">
            <div className="balance">
                <AmountComponent />
                <DepositComponent />
            </div>
            <UserComponent />
        </div>
    )
}

const LoginRegisterButtons = ({ deposit }) => {
    
    const { setLoginPopup } = useLoginPopup()
    const {setQuickRegisterPopup } = useQuickRegisterPopup()

    return (
        <div className="buttons">
            <Button secondary colorTheme={deposit} onClickEvent={ () => { setLoginPopup(true) }}>Login</Button>
            <Button colorTheme={deposit} onClickEvent={ () => { setQuickRegisterPopup(true); }}>Sign Up</Button>
        </div>
    )
}