import React, { useState, useEffect } from "react"

export const MenuProviderContext = React.createContext({
	menuItems: [],
    setMenuItems: () => {}
})

export const MenuProvider = ({ children }) => {
    const [ menuItems, setMenuItems ] = useState([]);

	return (
		<MenuProviderContext.Provider value={{ menuItems, setMenuItems }}>
			{ children }
		</MenuProviderContext.Provider>
	)

}

export const useMenuProvider = () => {
	
	const { menuItems, setMenuItems } = React.useContext(MenuProviderContext)

    if(menuItems === undefined && setMenuItems === undefined ){
      throw new Error('useMenuProvider was used outside its Provider!')
    }


    useEffect(() => {
        setMenuItems([
			{
				name: 'Edit profile',
				to: '/my-account/edit-profile',
				image: '/images/icons/person.svg'
			},
			{
				name: 'Deposit',
				to: '/my-account/deposit',
				image: '/images/icons/wallet-deposit.svg'
			},
			{
				name: 'Withdraw',
				to: '/my-account/withdraw',
				image: '/images/icons/wallet-withdraw.svg'
			},
			{
				name: 'Pending Withdrawals',
				to: '/my-account/pending-withdrawl',
				image: '/images/icons/wallet-pending-withdraw.svg'
			},
			{
				name: 'Bonuses',
				to: '/my-account/bonuses',
				image: '/images/icons/coins.svg'
			},
			{
				name: 'Transaction History',
				to: '/my-account/transaction-history',
				image: '/images/icons/transaction.svg'
			},
			{
				name: 'Change E-mail',
				to: '/my-account/change-email',
				image: '/images/icons/icon-change-email.svg'
			},
			{
			  name: 'Change Password',
			  to: '/my-account/change-password',
			  image: '/images/icons/icon-change-password.svg'
			},
			{
				name: 'Deposit Limits',
				to: '/my-account/deposit-limit',
				image: '/images/icons/deposit-limits.svg'
			},
			/* {
				name: 'Reality Check',
				to: '/my-account/reality-check',
				image: '/images/icons/reality-check.svg'
			}, */
			{
				name: 'Cool Off Period',
				to: '/my-account/cool-off-period',
				image: '/images/icons/cool-off.svg'
			},
			{
				name: 'Self Exclusion',
				to: '/my-account/self-exclusion',
				image: '/images/icons/self-exclusion.svg'
			},
			/* {
				name: 'Inbox',
				to: '/my-account/inbox',
				image: '/images/icons/inbox.svg'
			}, */
			{
				name: 'Documents',
				to: '/my-account/documents',
				image: '/images/icons/settings.svg'
			},
			/* {
				name: 'Privacy Settings',
				to: '/my-account/privacy-settings',
				image: '/images/icons/privacy-settings.svg'
			},
			{
				name: 'SMS Notification management',
				to: '/my-account/sms-notifications',
				image: '/images/icons/notification.svg'
			},
			{
				name: 'Settings',
				to: '/my-account/settings',
				image: '/images/icons/settings.svg'
			},
			{
				name: 'Loyalty',
				to: '/my-account/loyalty',
				image: '/images/icons/bedge.svg'
			},  */
        ]);
    }, [setMenuItems])

	return menuItems;
 
}