import React from 'react'
import styled from 'styled-components'
import { useAppMessage } from '../../../contexts/AppLoadedProvider'
import MimaLoader from './MimaLoader'

export default function AppLoaderStatic(){

    const { appMessage } = useAppMessage()

    return (
        <AppLoaderWrap>
            <MimaLoader />
            { appMessage && <AppNotice dangerouslySetInnerHTML={{ __html: appMessage }} className="app-notice"></AppNotice> }
            { !appMessage && <p>Loading request...</p> }
        </AppLoaderWrap>
    )
}

const AppLoaderWrap = styled.div`
    position: fixed;
    z-index: 999999;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        width: 200px;
        height: auto;
    }
    p{
        margin-top: 1.25rem;
        font-size: 1rem;
    }
`

const AppNotice = styled.div`
    text-align: center;
    margin-left: -15px;
    padding: 25px;
    line-height: 24px;
    background: rgba(85, 57, 3, 0.9);
    border-radius: 15px;
`