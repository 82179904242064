import React, { useState } from "react"

export const SocketLoadedContext = React.createContext({
	socketLoaded: false,
	setSocketLoaded: () => {}
})

export const SocketLoadedProvider = ({ children }) => {

	const [socketLoaded, setSocketLoaded] = useState(false);

	return (
		<SocketLoadedContext.Provider value={{ socketLoaded, setSocketLoaded }}>
			{ children }
		</SocketLoadedContext.Provider>
	)

}

export const useSocketLoaded = () => {

	const { socketLoaded, setSocketLoaded } = React.useContext(SocketLoadedContext)

	if(socketLoaded === undefined && setSocketLoaded === undefined ){
		throw new Error('useSocketLoaded was used outside its Provider!')
	}

	return { socketLoaded, setSocketLoaded }

}