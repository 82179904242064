import React, { useState, useEffect } from "react"
import useSocketCall from '../api/useSocketCall'

export const CurrencyProviderContext = React.createContext({
	currencies:[],
	setCurrencies: () => {},
})

export const PhonePrefixesContext = React.createContext({
	phonePrefixes: [],
	setPhonePrefixes: () => {}
})

export const CountriesProviderContext = React.createContext({
	customPrefixLables: [],
	setCustomPrefixLables: () => {},
	countries:[],
	setCounries: () => {},
	countriesResponse:[],
	setCountriesResponse: () => {},
})

export const EmVariablesProvider = ({ children }) => {

    const [ customPrefixLables, setCustomPrefixLables ] = useState([])
    const [ countries, setCounries ] = useState([])
    const [ countriesResponse, setCountriesResponse ] = useState([])
    const [ currencies, setCurrencies ] = useState([])
    const [ phonePrefixes, setPhonePrefixes ] = useState([])


	return (
		<CountriesProviderContext.Provider value={{ customPrefixLables, setCustomPrefixLables, countries, setCounries, countriesResponse, setCountriesResponse }}>
			<CurrencyProviderContext.Provider value={{ currencies, setCurrencies }}>
				<PhonePrefixesContext.Provider value={{ phonePrefixes, setPhonePrefixes }}>
					{ children }
				</PhonePrefixesContext.Provider>
			</CurrencyProviderContext.Provider>
		</CountriesProviderContext.Provider>
	)

}


export const usePhonePrefixesProvider = () => {

	const { phonePrefixes, setPhonePrefixes} = React.useContext(PhonePrefixesContext)
	
	if(phonePrefixes === undefined && setPhonePrefixes === undefined ){
		throw new Error('usePhonePrefixesProvider was used outside its Provider!')
	}
    const { response } = useSocketCall('/user/account#getPhonePrefixes')

    useEffect(() => {
        if(!response) return;
		
        var prepareObject = [];

        response.kwargs.map((item) => {

			let temp = {
                value: item,
                label: item
            }
            prepareObject.push(temp);
            return null
        });
		
		setPhonePrefixes(prepareObject)
    }, [response,setPhonePrefixes])
    

	return { phonePrefixes }

}


export const useCurrencyProvider = () => {
	
	const { currencies, setCurrencies} = React.useContext(CurrencyProviderContext)
	
	if(currencies === undefined && setCurrencies === undefined ){
		throw new Error('useCurrencyProvider was used outside its Provider!')
	}
    const { response } = useSocketCall('/user/account#getCurrencies')


    useEffect(() => {
        if(!response) return;
		
        var prepareObject = [];

        response.kwargs.map((item) => {

			let temp = {
                value: item.code,
                label: item.name
            }
            prepareObject.push(temp);
            return null
        });
		
		setCurrencies(prepareObject)
    }, [response,setCurrencies])
    

	return { currencies }
 
}


export const useCountriesProvider = () => {
	
	const {  customPrefixLables, setCustomPrefixLables,countries, setCounries ,countriesResponse, setCountriesResponse} = React.useContext(CountriesProviderContext)
	
	if(customPrefixLables === undefined && setCustomPrefixLables === undefined ){
		throw new Error('useCountriesProvider was used outside its Provider!')
	}
	
	
	const { response } = useSocketCall('/user/account#getCountries')

	useEffect(() => {
        if(!response) return;
        
        var obj = {};
		var prepareObject = [];
		var phonePrefixObject = [];

		setCountriesResponse(response.kwargs.countries);
        
        response.kwargs.countries.map((item) => {

			phonePrefixObject.push({
				value:item.phonePrefix,
				label:item.phonePrefix
			})
			/* Custom prefixes used in mobile prefix select box */
            let key = item.code;
            obj[key] = { secondary: item.phonePrefix };

			/* Countries values preparation for select */
			let temp = {
                value: item.code,
                label: item.name
            }
            prepareObject.push(temp);

            return null
        });
		
		setCounries(prepareObject)
        setCustomPrefixLables(obj);

    }, [response,setCounries,setCustomPrefixLables, setCountriesResponse])

	return { customPrefixLables, setCustomPrefixLables, countries, setCounries, countriesResponse, setCountriesResponse }
 
}