import React, {lazy, Suspense} from 'react'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import AppProvider from './contexts/AppProvider'
import AppLoaderStatic from './components/Utils/AppLoader/AppLoaderStatic'
import Header from './components/General/Header/Header'
import ScrollToTop from './components/Utils/ScrollToTop'

const AppLoader = lazy(() => import('./components/Utils/AppLoader/AppLoader'))
const ProtectedRoute = lazy(() => import('./components/Authentication/ProtectedRoute/ProtectedRoute'))
const MainLayout = lazy(() => import('./components/Layout/MainLayout'))

const MLHB = lazy(() => import('./components/Layout/MainLayoutHeroBanner'))

//const ScrollToTop = lazy(() => import('./components/Utils/ScrollToTop'))
//const Header = lazy(() => import('./components/General/Header/Header'))
const Footer = lazy(() => import('./components/General/Footer/Footer'))

const Landing = lazy(() => import('./pages/Landing'))
const SingleGame = lazy(() => import('./pages/SingleGame'))

const Login = lazy(() => import('./pages/Auth/Login'))
const Register = lazy(() => import('./pages/Auth/Register'))
const LegalPage = lazy(() => import('./pages/LegalPage'))
const LivePoker = lazy(() => import('./pages/LivePoker'))

const PersonalInformationPage = lazy(() => import('./pages/Profile/PersonalInformationPage'))
const ManageBalancePage = lazy(() => import('./pages/Profile/ManageBalancePage'))
const DepositPage = lazy(() => import('./pages/Profile/DepositPage'))
const WithdrawPage = lazy(() => import('./pages/Profile/WithdrawPage'))

const BonusesPage = lazy(() => import('./pages/Profile/BonusesPage'))
const LoyaltyPage = lazy(() => import('./pages/Profile/LoyaltyPage'  ))
const TransactionHistoryPage = lazy(() => import('./pages/Profile/TransactionHistoryPage'))
//const InboxPage = lazy(() => import('./pages/Profile/InboxPage'))
const DepositLimit = lazy(() => import('./pages/Profile/DepositLimitPage'))
const ThankYouPage = lazy(() => import('./pages/ThankYouPage'))
const FailedPage = lazy(() => import('./pages/FailedPage'))
const BlogPage = lazy(() => import('./pages/BlogPage'))
const BlogPostPage = lazy(() => import('./pages/BlogPostPage'))
const ChangeEmailPage = lazy(() => import('./pages/Profile/ChangeEmailPage'))
const PendingWithdrawalPage = lazy(() => import('./pages/Profile/PendingWithdrawalPage'))

const TermsAndConditionsPage = lazy(() => import('./pages/Help/TermsAndConditionsPage'))
const FaqPage = lazy(() => import('./pages/Help/FaqPage'))
const ContactPage = lazy(() => import('./pages/Help/ContactPage'))

const Profile = lazy(() => import('./pages/Profile'))
const ResponsibleGambing = lazy(() => import('./pages/ResponsibleGambing'))

const DepositLimitsChangePage = lazy(() => import('./pages/Profile/DepositLimitsChangePage'))
const Activate = lazy(() => import('./pages/Auth/Activate' ))
const VerifyEmail = lazy(() => import('./pages/Auth/VerifyEmail' ))
const ChangePasswordPage = lazy(() => import('./pages/Auth/ChangePasswordPage' ))
const ForgotPassword = lazy(() => import('./pages/Auth/ForgotPassword'))
const ResetPassword = lazy(() => import('./pages/Auth/ResetPassword'))
const CoolOffPeriodPage = lazy(() => import('./pages/Profile/CoolOffPeriodPage'))
const SelfExclusionPage = lazy(() => import('./pages/Profile/SelfExclusionPage'))
const ThankYou = lazy(() => import('./components/Utils/ThankYou/ThankYou'))
const DocumentsPage = lazy(() => import('./pages/Profile/DocumentsPage'))

function App() {
	
	return (
		<Router>
			<ScrollToTop>
				<HelmetProvider>
					<AppProvider>

						<Header />

						<Suspense fallback={<AppLoaderStatic />}>

							<MainLayout>
								<Routes>

									<Route path="*" element={<ThankYou title='404' text='Page not found...' showContact={false} />} />

									<Route exact path="/" element={<MLHB><Landing/></MLHB>} />

									<Route exact path="/live-poker-lobby" element={<LivePoker />} />

									<Route exact path="/login" element={<Login/>} />
									<Route exact path="/register" element={<Register/>} />
									
									<Route exact 
									path="/register-thank-you" 
									element={<ThankYou src='/images/thankyoupositive.png' 
									title='Thank You for Registering!' 
									text='Please proceed by logging in with your new username and password'
									button={{ to: '/login', label: 'Login'  }} />} />

									<Route exact path="/my-profile" element={<Profile/>} />
									<Route exact path="/responsible-gambling" element={<ResponsibleGambing/>} />

									<Route exact path="/activate" element={<Activate/>} />
									<Route exact path="/verify-email" element={<VerifyEmail/>} />

									<Route exact path="/forgot-password" element={<ForgotPassword/>} />
									<Route exact path="/reset-password" element={<ResetPassword/>} />

									<Route exact path="/blog" element={<BlogPage/>} />
									<Route exact path="/blog/:slug" element={<BlogPostPage/>} />

									<Route exact path="/game/demo/:slug" element={<SingleGame demo={true} />} />
									
									<Route element={<ProtectedRoute />}>
										<Route exact path="/game/:slug" element={<SingleGame />} />

										<Route exact path="/my-account/edit-profile" element={<PersonalInformationPage/>} />
										<Route exact path="/my-account/change-email" element={<ChangeEmailPage/>} />
										<Route exact path="/my-account/change-password" element={<ChangePasswordPage/>} />
										
										<Route exact path="/my-account/manage-balance" element={<ManageBalancePage/>} />
										<Route exact path="/my-account/deposit" element={<DepositPage/>} />
										<Route exact path="/my-account/withdraw" element={<WithdrawPage/>} />

										<Route exact path="/my-account/bonuses" element={<BonusesPage/>} />
										<Route exact path="/my-account/loyalty" element={<LoyaltyPage/>} />
										<Route exact path="/my-account/transaction-history" element={<TransactionHistoryPage/>} />
										{/* <Route exact path="/my-account/inbox" element={<InboxPage/>} /> */}
										<Route exact path="/my-account/documents" element={<DocumentsPage/>} />
										<Route exact path="/my-account/deposit-limit" element={<DepositLimit/>} />
										<Route exact path="/my-account/deposit-limit/:type" element={<DepositLimitsChangePage/>} />
										<Route exact path="/my-account/deposit/thank-you-page" element={<ThankYouPage/>} />
										<Route exact path="/my-account/deposit/failed-page" element={<FailedPage/>} />

										<Route exact path="/my-account/cool-off-period" element={<CoolOffPeriodPage/>} />
										<Route exact path="/my-account/self-exclusion" element={<SelfExclusionPage/>} />
										<Route exact path="/my-account/pending-withdrawl" element={<PendingWithdrawalPage/>} />

										<Route exact path='/help/contact-us' element={<ContactPage/>}/>
											
									</Route>

									<Route exact path='/help/terms-and-conditions' element={<TermsAndConditionsPage/>}/>
									<Route exact path='/help/faq' element={<FaqPage/>}/>
									<Route exact path="/help/:slug" element={<LegalPage/>} />
									
									<Route exact path="/security-privacy/:slug" element={<LegalPage/>} />

								</Routes>
							</MainLayout>

							<Footer />
							<AppLoader />
							
						</Suspense>

					</AppProvider>
				</HelmetProvider>
			</ScrollToTop>
		</Router>
	)
}

export default App;
