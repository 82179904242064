import React, { useEffect, useState, useContext } from "react"
import autobahn from "autobahn"

import { useSocketLoaded } from './SocketLoadedProvider'
import { useAppLoaded, useAppMessage } from "./AppLoadedProvider"
import ThankYou from "../components/Utils/ThankYou/ThankYou"
import AppLoaderStatic from "../components/Utils/AppLoader/AppLoaderStatic"

const WSS_URL = process.env.REACT_APP_WSS_URL
const WSS_REALM = process.env.REACT_APP_WSS_REALM

export const EveryMatrixContext = React.createContext(null)

export const EveryMatrixProvider = ({ children }) => {
	
	const [ socket, setSocket ] = useState(null)
	const [ sessionSocket, setSessionSocket ] = useState(null);
	const { socketLoaded, setSocketLoaded } = useSocketLoaded()
	const { setAppLoaded } = useAppLoaded()
	const { setAppMessage } = useAppMessage()
	const [ canRegister, setCanRegister ] = useState(false) 
	const [ canRegisterLoaded, setCanRegisterLoaded ] = useState(false);



	useEffect(() => {
		
		if(socketLoaded){ return }

		setSocket(new autobahn.Connection({
			url: WSS_URL, 
			realm: WSS_REALM,
			max_retries: 3,
			retry_if_unreachable: true
		}))

	}, [socketLoaded])
	


	useEffect(() => {
		
		console.log('Before Socket', socket, socketLoaded)

		if(!socket || socketLoaded){ return }

		console.log('Socket is set', socket, socketLoaded)

		socket.onopen = function (session) {

			setSessionSocket(session)
			setSocketLoaded(true)

			if(!localStorage.getItem('user_cms_session')){
				console.log('Local Storage parameter user_cms_session does not exists, continue loading the APP')
				setAppLoaded(true)
			}

			console.log('Socket opened...')

			session.call('/user/account#ensureRegistrationIsAllowed').then(
				function(result){
					console.log('Registration is allowed for this user...')
					setCanRegister(true)
					setCanRegisterLoaded(true)
				},
				function(err){
					console.log('Registration is NOT allowed for this user...', err)
					setCanRegister(false)
					setCanRegisterLoaded(true)
				}
			)

			//
		}

		socket.onclose = function(response){

			console.log('Socket closed...')

			if(response==='unreachable'){
				localStorage.removeItem('user_cms_session')
				setAppMessage('Sorry for the inconvinience.<br />We are currently experiencing network issues. Please check back later.')
				setAppLoaded(false)
			}else{
				window.location.href = '/'
			}
			setSocketLoaded(false)
		};
		
		socket.onerror = function(err){ 
			console.log('Socket error...')
			localStorage.removeItem('user_cms_session')
			window.location.href = '/'
		};


		socket.open();

		
	}, [socket, socketLoaded, setSocketLoaded, setAppLoaded, setAppMessage, setCanRegister])

	return (	
		
		<EveryMatrixContext.Provider value={sessionSocket}>
			{ canRegisterLoaded ?  ( canRegister ?  
				children : <ThankYou src='/images/thankyounegative.png'  text='Sorry, we are not available in your country/region right now.' showContact={false} showButtons={false} /> ) : ( <AppLoaderStatic/>)}
		</EveryMatrixContext.Provider>
	)

}

export const useSessionSocket = () => {
	// get the context
	const context = useContext(EveryMatrixContext);
  
	// if `undefined`, throw an error
	if (context === undefined) {
		throw new Error("useSessionSocket was used outside of its Provider");
	}

  
	return context;
};
