import React, { useEffect, useState, useCallback } from "react"
import { useSessionSocket } from "./EveryMatrixProvider"
import { useUserLogged } from "./UserLoggedProvider"
import { useAppLoaded } from "./AppLoadedProvider"
import { useUserWalletProvider } from './UserWalletProvider'
import { useNavigate } from "react-router-dom"

export const UserSessionContext = React.createContext({
	userSession: null,
	setUserSession: () => {},
	userLogin: null,
	setUserLogin: () => {},
	customLoginOpened: false,
	setCustomLoginOpened: () => {},
})

export const UserSessionProvider = ({ children }) => {
	
	const session = useSessionSocket()
	const navigate = useNavigate()

	const { setAppLoaded } = useAppLoaded()
	const { userLogged, setUserLogged } = useUserLogged()
	const [ userSession, setUserSession ] = useState(null)
	const [ userLogin, setUserLogin ] = useState(null)
    const { setUserWallet } = useUserWalletProvider()
	const [ currency, setCurrency ] = useState(''); 
	const [ customLoginOpened, setCustomLoginOpened ] = useState(false); 
	const [ gaEventPushed, setGaEventPushed ] = useState(false);

	const [ balanceSubscibed, setBalanceSubscribed ] = useState(false)


	/* useEffect(() => {
        window.dataLayer.push({
            event: 'login'
          });
    }, []) */

	useEffect(() => {
		//session
		if(!localStorage.getItem("user_cms_session") || !session){ return }

		console.log('CMS Session is: ' + localStorage.getItem("user_cms_session"))

		session.call( '/user#loginWithCmsSessionID', [], { sessionID: localStorage.getItem("user_cms_session") }).then(
			function(result) {
				if(!result.kwargs){ return }
				setUserLogged(true)
				setUserLogin(result.kwargs)
			},
			function(err) {
				console.log(err)
				setUserLogged(false)
				localStorage.removeItem('user_cms_session')
				if(err.kwargs.desc === 'Session is not authenticated.' || err.kwargs.desc === 'Failed to locate session.'){
					window.location.reload()
				}

				return err
			}
		)
		
	}, [session, setUserLogged])

	const getGamingAccountsSetValues = useCallback(
		() => {

			var parametars = {
				expectBalance: true,
				expectBonus : true
			}
	
			session.call('/user/account#getGamingAccounts', [], parametars).then(
				function(result){

					console.log(result)
					
					var currency_symbols = {
						'USD': '$', // US Dollar
						'EUR': '€', // Euro
						'CRC': '₡', // Costa Rican Colón
						'GBP': '£', // British Pound Sterling
						'ILS': '₪', // Israeli New Sheqel
						'INR': '₹', // Indian Rupee
						'JPY': '¥', // Japanese Yen
						'KRW': '₩', // South Korean Won
						'NGN': '₦', // Nigerian Naira
						'PHP': '₱', // Philippine Peso
						'PLN': 'zł', // Polish Zloty
						'PYG': '₲', // Paraguayan Guarani
						'THB': '฿', // Thai Baht
						'UAH': '₴', // Ukrainian Hryvnia
						'VND': '₫', // Vietnamese Dong
						'RUB': '₽', // Russian Rubles
					};
	
					
					if(currency_symbols[result.kwargs.accounts[0].currency]){
						setCurrency(currency_symbols[result.kwargs.accounts[0].currency]);
					}else{
						setCurrency(result.kwargs.accounts[0].currency);
					}
					
					let temp = {
						amount:result.kwargs.accounts[0].amount,
						bonusAmount:result.kwargs.accounts[1].amount,
						currency:currency_symbols[result.kwargs.accounts[0].currency] ? currency_symbols[result.kwargs.accounts[0].currency] : result.kwargs.accounts[0].currency
					};
	
					setUserWallet(temp);
	
					
				},
				function(err){
					return err
				}
			)
		}, [session, setUserWallet],
	);



	useEffect(() => {

		if(!userLogged || !session){ return }

		session.call( '/user#getSessionInfo', []).then(
			function(result) {			
				
				if(!result.kwargs){ return }
				setUserSession(result.kwargs)

				if(window && window.dataLayer && !gaEventPushed){
					window.dataLayer.push({
						"event": 'login',
						"user_id": result.kwargs.userID
					});
					setGaEventPushed(true);
				}

				if(result.kwargs.roles && result.kwargs.roles.includes("Anonymous Registration")){
					if(!localStorage.getItem('anonymous_popup')){
						setCustomLoginOpened(true);
						localStorage.setItem("anonymous_popup",1);
					}
				}
				
				getGamingAccountsSetValues()
				
				if(balanceSubscibed){ return }

				session.call('/user/account#watchBalance', []).then(
					function(result){
						setBalanceSubscribed(true)

						session.subscribe('/account/balanceChanged', function(uri, data){
							
							console.log('Balance Changed!' + currency)
							getGamingAccountsSetValues()

							/* let temp = {
								amount: data.amount,
								bonusAmount: data.bonusAmount,
								currency: currency
							}
		
							setUserWallet(temp); */
							
						})
						
						return result
					},
					function(err){
						console.log(err)
						return err
					}
				)

				

			},
			function(err) {
				return err
			}
		)

	}, [getGamingAccountsSetValues, balanceSubscibed, userLogged, session, currency, setUserWallet, gaEventPushed])


	useEffect(() => {

		if(userSession && !userSession.email && !userSession.isAuthenticated && userSession.userID===0){
			setTimeout(() => {
				localStorage.removeItem('user_cms_session')
				localStorage.removeItem("anonymous_popup");
				localStorage.removeItem('rc');
				session.call('/user#logout')
				alert('There was an error with your user. Please contact our support.')
				window.location.reload()
			}, 500)
		}

		console.log('Set AppLoaded to true if there is userSession, userLogin and Session active')
		console.log(userSession, userLogin, session)

		if(userSession && userLogin && session){ 
			setAppLoaded(true)
		}

	}, [userSession, navigate, userLogin, session, setAppLoaded])


	return (
		<UserSessionContext.Provider value={{ userSession, setUserSession, userLogin, setUserLogin, customLoginOpened, setCustomLoginOpened }}>
			{ children }
		</UserSessionContext.Provider>
	)

}



export const useUserSession = () => {

	const { userSession, setUserSession, userLogin, setUserLogin, customLoginOpened, setCustomLoginOpened } = React.useContext(UserSessionContext)

	if(userSession === undefined && setUserSession === undefined ){
		throw new Error('useUserSession was used outside its Provider!')
	}

	return { userSession, setUserSession, userLogin, setUserLogin, customLoginOpened, setCustomLoginOpened }

}