import React from 'react';

import { UserLoggedProvider } from './UserLoggedProvider'
import { LoginPopupProvider } from './LoginPopupProvider'
import { SocketLoadedProvider } from './SocketLoadedProvider'
import { EveryMatrixProvider } from './EveryMatrixProvider'
import { UserSessionProvider } from './UserSessionProvider'
import { AppLoadedProvider } from './AppLoadedProvider'
import { AppOptionsProvider } from './AppOptionsProvider'
import { UserWalletProvider } from './UserWalletProvider';
import { MenuProvider } from './MenuProvider';
import { EmVariablesProvider } from './EmVariablesProvider';

export default function AppProvider({ children }){
    return (
        <AppLoadedProvider>
            <AppOptionsProvider>
                <MenuProvider>
                    <SocketLoadedProvider>
                        <UserWalletProvider>
                            <EveryMatrixProvider>
                                <UserLoggedProvider>
                                    <LoginPopupProvider>
                                        <EmVariablesProvider>
                                            <UserSessionProvider>
                                                    { children }
                                            </UserSessionProvider>
                                        </EmVariablesProvider>
                                    </LoginPopupProvider>
                                </UserLoggedProvider>
                            </EveryMatrixProvider>
                        </UserWalletProvider>
                    </SocketLoadedProvider>
                </MenuProvider>
            </AppOptionsProvider>
        </AppLoadedProvider>
    )
}