import React from 'react';
import styled, { css, keyframes } from 'styled-components';

export default function MimaLoader(){
    return (
        <MimaLoaderDiv>
            <MimaCircle>
                <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0.399902L18 11.8999L35.5 0.399902V34.8999H0V0.399902Z" fill="white"/>
                </svg>
            </MimaCircle>
            <MimaRing1 />
            <MimaRing2 />
            <MimaRing3 />
            <MimaRing4 />
            <MimaRing5 />
        </MimaLoaderDiv>
    )
}

const centerAbs = css`
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`

const MimaLoaderDiv = styled.div`
    width: 180px;
    height: 180px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`

const breatheAnimation = keyframes`
    0% { transform: scale(1); }
    20% { transform: scale(1.2);  }
    30% { transform: scale(1.2);  }
    100% { transform: scale(1); }
`

const MimaCircle = styled.div`
    border-radius: 100%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--default);
    animation-name: ${breatheAnimation};
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    z-index: 15;
    svg{
        float: left;
        width: 40%;
        height: auto;
    }
`

const breatheAnimation1 = keyframes`
    0% { opacity: 0; transform: scale(1); }
    20% { opacity: 1; transform: scale(1.35);  }
    30% { opacity: 1; transform: scale(1.35);  }
    100% { opacity: 0; transform: scale(1); }
`
const MimaRing1 = styled.div`
    width: 60px;
    height: 60px;
    position: absolute;
    ${centerAbs}
    border-radius: 100%;
    border: 1px solid var(--default);
    transform: scale(1);
    animation-name: ${breatheAnimation1};
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
`

const breatheAnimation2 = keyframes`
    0% { opacity: 0; transform: scale(1); }
    20% { opacity: 1; transform: scale(1.55);  }
    30% { opacity: 1; transform: scale(1.55);  }
    100% { opacity: 0; transform: scale(1); }
`
const MimaRing2 = styled.div`
    width: 60px;
    height: 60px;
    position: absolute;
    ${centerAbs}
    border-radius: 100%;
    border: 1px solid var(--default);
    transform: scale(1);
    opacity: 0.8;
    animation-name: ${breatheAnimation2};
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
`

const breatheAnimation3 = keyframes`
    0% { opacity: 0; transform: scale(1); }
    20% { opacity: 1; transform: scale(1.75);  }
    30% { opacity: 1; transform: scale(1.75);  }
    100% { opacity: 0; transform: scale(1); }
`
const MimaRing3 = styled.div`
    width: 60px;
    height: 60px;
    position: absolute;
    ${centerAbs}
    border-radius: 100%;
    border: 1px solid var(--default);
    transform: scale(1);
    opacity: 0.6;
    animation-name: ${breatheAnimation3};
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
`

const breatheAnimation4 = keyframes`
    0% { opacity: 0; transform: scale(1); }
    20% { opacity: 1; transform: scale(1.95);  }
    30% { opacity: 1; transform: scale(1.95);  }
    100% { opacity: 0; transform: scale(1); }
`
const MimaRing4 = styled.div`
    width: 60px;
    height: 60px;
    position: absolute;
    ${centerAbs}
    border-radius: 100%;
    border: 1px solid var(--default);
    transform: scale(1);
    opacity: 0.6;
    animation-name: ${breatheAnimation4};
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
`

const breatheAnimation5 = keyframes`
    0% { opacity: 0; transform: scale(1); }
    20% { opacity: 1; transform: scale(2.15);  }
    30% { opacity: 1; transform: scale(2.15);  }
    100% { opacity: 0; transform: scale(1); }
`
const MimaRing5 = styled.div`
    width: 60px;
    height: 60px;
    position: absolute;
    ${centerAbs}
    border-radius: 100%;
    border: 1px solid var(--default);
    transform: scale(1);
    opacity: 0.6;
    animation-name: ${breatheAnimation5};
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
`