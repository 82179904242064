import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { buttonElementCss } from './ButtonElementCss'

const Button = ({onClickEvent=null, colorTheme="default", secondary=false, children, theme, className, to=''}) => {

	const navigate = useNavigate()

	const handleClick = (e) => {
		if(!onClickEvent && !to) return;
		
		if(to){ 
			navigate(to)
			return
		}

		e.preventDefault()
		onClickEvent()
	}

	return (
		<ButtonElement 
			onClick={handleClick} 
			secondary={secondary} 
			colorTheme={colorTheme==='home' ? 'default' : colorTheme} 
			className={'btn' + (theme ? ' ' + theme : '')  + (className ? ' ' + className : '') + (secondary ? ' btn-secondary' : '')}>
				{children}
		</ButtonElement>
	)
}

export default Button


const ButtonElement = styled.button`
	${buttonElementCss}
`