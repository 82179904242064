import React from 'react'
import { Link } from 'react-router-dom'

import Image from '../Image/Image.jsx'
import Button from '../Button/Button.jsx'

import './ThankYou.scss'

const ThankYou = ({src=null, title='', text='', button=null, showContact=true, background='/images/promo-banners/Thankyou_backgrounds.jpg'}) => {

	return (
	<div className='thankyou-page' style={{ backgroundImage: 'url("'+ background +'")' }}>
		{ src && <Image src={src} /> }
		<h3 className="thankyou-title">{title}</h3>
		{ text && <p className="thankyou-text" dangerouslySetInnerHTML={{ __html: text }}></p> }
		
		{ showContact && <p className="thankyou-trouble">Having trouble? <Link to="/help/contact-us">Contact us</Link></p> }

		{ !button && <Button to="/">Continue to homepage</Button> }
		{ button && button.to && button.label && <Button to={ button.to }>{ button.label }</Button> }
	</div>
	)
}

export default ThankYou