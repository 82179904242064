import { useState, useEffect } from 'react';
import axios from 'axios'
import axiosRetry from 'axios-retry'

axiosRetry(axios, { retries: 3, retryDelay: () => { return 500 } })

const useGetWPAxios = (callMethod) => {

    const [response, setResponse] = useState(undefined);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    const fetchData = async (callMethod) => {
		try {
			const apiUrl = process.env.REACT_APP_WP_API_URL + callMethod
			const result = await axios.get(apiUrl);
        setResponse(result.data);
      } catch( error ) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    

    useEffect(() => {
        fetchData(callMethod);
    }, [callMethod]);

    return { response, error, loading };
};

export default useGetWPAxios