import React from "react"
import { isWebpSupported } from "react-image-webp/dist/utils"
import useGetWPAxios from "../api/useGetWPAxios"

export const AppOptionsContext = React.createContext([])
export const AppMainBannerContext = React.createContext([])

export const AppOptionsProvider = ({ children }) => {

	const { response } = useGetWPAxios('acf/v3/options/options')

	return (
		<AppOptionsContext.Provider value={ response && response }>
			<AppBannerProvider>
				{ children }
			</AppBannerProvider>
		</AppOptionsContext.Provider>
	)

}

const AppBannerProvider = ({ children }) => {
	
	const { response } = useGetWPAxios('wp/v2/pages/84')

	return (
		<AppMainBannerContext.Provider value={ response && response.acf }>
			{ children }
		</AppMainBannerContext.Provider>
	)
}

export const useAppOptions = () => {
	const appOptions = React.useContext(AppOptionsContext)
	if(appOptions === undefined ) return
	return appOptions
}

export const useAppMainBanner = () => {
	const appBanner = React.useContext(AppMainBannerContext)
	if(appBanner === undefined ) return
	return appBanner
}

export const useDefaultAppBannerStyle = () => {
	const appOptions = React.useContext(AppOptionsContext)
	const defaultBanner = appOptions && appOptions.acf && appOptions.acf.default_banner && appOptions.acf.default_banner.url
    const defaultBannerStyle = { backgroundImage: 'url("'+ defaultBanner + (isWebpSupported ? '.webp' : '') +'")' }
	if(!appOptions) return
	if(!defaultBanner) return
	return defaultBannerStyle
}